import Vue from 'vue';
import VueRouter from 'vue-router';
// import {serverCheck } from "@api/njApi/honggan.js";

Vue.use(VueRouter);
let routes = [
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/',
    name: 'Home',
    meta: { title: '首页' },
    component: () => import('../views/home.vue'),
    // meta: { requiresAuth: true }, // 添加 meta 字段，表示需要登录验证
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   meta: { title: "首页" },
  //   component: () => import("../views/home.vue"),
  //   // meta: { requiresAuth: true }, // 添加 meta 字段，表示需要登录验证
  // },
  {
    path: '/seedling-overview',
    name: 'seedling-overview',
    meta: { title: '育秧总览' },
    component: () => import('../views/seedling/seedling-overview.vue'),
  },
  {
    path: '/seedling-controlView',
    name: 'seedling-controlView',
    meta: { title: '育秧订单' },
    component: () => import('../views/seedling/seedling-controlView.vue'),
  },
  {
    path: '/seedling-orderView',
    name: 'seedling-orderView',
    meta: { title: '育秧数字管控' },
    component: () => import('../views/seedling/seedling-orderView.vue'),
  },
  {
    path: '/drying-service',
    name: 'drying-service',
    meta: { title: '烘干服务' },
    component: () => import('../views/drying/index.vue'),
  },
  {
    path: '/drying-Num',
    name: 'drying-Num',
    meta: { title: '数字管控' },
    component: () => import('../views/drying/indexx.vue'),
  },
  {
    path: '/process',
    name: 'process',
    meta: { title: '加工服务' },
    component: () => import('../views/processAll/process/index.vue'),
  },
  {
    path: '/process-Num',
    name: 'process-Num',
    meta: { title: '加工数字管控' },
    component: () => import('../views/processAll/process-num/index.vue'),
  },
  {
    path: '/njserve',
    name: 'njserve',
    meta: { title: '农机服务' },
    component: () => import('../views/njall/NjIndex.vue'),
  },
  {
    path: '/njwx',
    name: 'njwx',
    meta: { title: '农机维修' },
    component: () => import('../views/njall/NjwxView.vue'),
  },
  {
    path: '/zyserve',
    name: 'zyserve',
    meta: { title: '作业服务' },
    component: () => import('../views/njall/Zyfw.vue'),
  },
  {
    path: '/njdd',
    name: 'njdd',
    meta: { title: '农机调度' },
    component: () => import('../views/njall/NjduView.vue'),
  },
  {
    path: '/njfw',
    name: 'njfw',
    meta: { title: '农技服务' },
    component: () => import('../views/njfw/index.vue'),
  },
  {
    path: '/sznt',
    name: 'sznt',
    meta: { title: '数字农田' },
    component: () => import('../views/number/index.vue'),
  },
  {
    path: '/overview',
    name: 'overview',
    meta: { title: '总农机数字总览' },
    component: () => import('../views/overview/index.vue'),
  },
  {
    path: '/totalControl',
    name: 'totalControl',
    meta: { title: '总农机数字管控' },
    component: () => import('../views/overview/totalControl.vue'),
  },
  {
    path: '/distribution',
    name: 'distribution',
    meta: { title: '农资配送' },
    component: () => import('../views/distribution/index.vue'),
  },{
    path: '/abandon',
    name: 'abandon',
    meta: { title: '农废回收' },
    component: () => import('../views/abandon/index.vue'),
  },
  {
    path: '/agricultureLearin',
    name: 'agricultureLearin',
    meta: { title: '农事研学' },
    component: () => import('../views/agricultureLearin/index.vue'),
  },
  {
    path: '/sales',
    name: 'sales',
    meta: { title: '农产品展销' },
    component: () => import('../views/sales2/index.vue'),
  },
  {
    path: '/storage',
    name: 'storage',
    meta: { title: '数字仓储' },
    component: () => import('../views/storage/index.vue'),
  },
];
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});
// 添加导航守卫;
router.beforeEach((to, from, next) => {

  // next("/login")
  // next({path: '/login'})
  let id = sessionStorage.getItem('ID');
  let tenantId = sessionStorage.getItem('tenantId');
  if(to.fullPath =='/login'){

    next();
  }else if (id&&tenantId) {
    next();
  } else {
    next({ path:'/login' }); // 如果未登录，跳转到登录页面
  }
  // 检查是否需要登录验证
  // if (to.matched.some((record) => record.meta.requiresAuth)) {
  // 检查用户是否已登录，可以根据自己的登录状态判断

  // } else {
  // next(); // 不需要登录验证的路由，直接通过
  // }
});
export default router;
