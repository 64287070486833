const state = {

};

const mutations = {

};

const actions = {

};

export default {
  state,
  mutations,
  actions
};
