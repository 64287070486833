// import storage from 'store'
import { login, logout } from '@/api/login/index.js';
// import {ACCESS_TOKEN,USER_ROLE} from "@/store/mutation-types";

const user = {
  state: {
    // name: '',
    // roles: [],

  },
  mutations: {
    // SET_NAME: (state, name) => {
    //     state.name = name
    // },
    // SET_ROLES: (state, roles) => {
    //     state.roles = roles
    // },
  },
  actions: {
    Login(context, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          console.log(response,'response');
          if(response.code == 1){
            sessionStorage.setItem('ID', response.data.id);
            sessionStorage.setItem('tenantId', response.data.tenantId);
            localStorage.setItem('address', response.data.tenantName);

            let objInfo = JSON.stringify(response.data);
            sessionStorage.setItem('INFO',objInfo);





            resolve(response);
          }else{
            reject(response);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },
    // Logout(context) {
    //     return new Promise((resolve) => {
    //         logout().then(() => {
    //             resolve()
    //         }).catch(() => {
    //             resolve()
    //         }).finally(() => {
    //             context.commit('SET_ROLES', [])
    //             context.commit('SET_NAME', '')
    //             storage.remove(ACCESS_TOKEN)
    //         })
    //     })
    // }
  }
};

export default user;
