<template>
  <div ref="chartContent" class="chartContent"/>
</template>
<script>
import * as echarts from 'echarts';
import axios from 'axios';
export default {
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    },
    mapData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object
    },
    actions: {
      type: Function,
      default:() => {
        return () => {};
      }
    },
    tooltipAuto:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      chart: null,
      geo: {},
      faultByHourTime: 0,
      faultByHourIndex: 0,
      timer:0,
      total:0,
      count:0,
      tooltip:0,
    };
  },
  watch: {
    option: {
      handler() {
        this.$nextTick(() => {
          if (this.mapData) JSON.stringify(this.geo) !== '{}' && this.initChart();
          else this.initChart();
        });
      },
      deep: true,
      immediate: true
    },
    mapData: {
      handler(val) {
        if(val){
          axios.get(val.geoUrl).then(data => {
            this.geo = data.data;
            this.initChart();
          });
        }
        else return;
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
  },
  destroyed() {
    // this.clearTooltip();
  },
  methods: {
    // 初始化echarts数据
    initChart() {
      const _this = this;
      this.chart = echarts.init(this.$refs.chartContent);
      if (this.mapData && JSON.stringify(this.geo) !== '{}') {
        echarts.registerMap(this.mapData.name, this.geo);
        this.chart.setOption(this.option, true);
      } else this.chart.setOption(this.option, true);
      // _this.chart.getZr().on('click', function(data) {

      this.chart.on('click', data => {
        let query = {
          key:'click',
          data
        };
        _this.actions(query);

      });
      this.chart.on('mouseover',data => {
        let query = {
          key:'mouseover',
          data
        };
        _this.actions(query);
      });
      this.chart.on('mouseout',data => {
        let query = {
          key:'mouseout',
          data
        };
        _this.actions(query);
      });
      if(this.tooltipAuto && window.tools) window.tools.loopShowTooltip(this.chart, this.option, { loopSeries: true });
      window.onresize = window.addEventListener('resize', function() {
        _this.chart.resize();
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.chartContent{
  width: 100%;
  height: 100%;
}
</style>
