export function myTypeof(val){
  let type = typeof(val);
  let toStr = Object.prototype.toString;
  let res = {
    '[object Array]':'array',
    '[object Object]':'object',
    '[object Number]':'object number',
    '[object String]':'object string',
    '[object Boolean]':'object boolean',
  };
  if(val === null){
    return 'null';
  }else if(type === 'object'){
    let ret = toStr.call(val);
    return res[ret];
  }else{
    return type;
  }
}

export function myConnect(){
  let conData = null;
  let dataType = this.$myTypeof(arguments[0]);
  if(![ 'array','object' ].includes(dataType)) return;
  if(dataType === 'array'){
    conData = [];
    for(let item of arguments) conData = conData.concat(item);
  } else {
    conData = {};
    for(let item of arguments) conData = Object.assign(conData,item);
  }
  return conData;
}
