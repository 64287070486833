import axios from 'axios';
import store from '../store/index.js';
import router from '@/router/index';
import { Notification, MessageBox } from 'element-ui';

// import { getToken } from '@/utils/auth';
import Config from '@/settings';
// var qs = require('qs');//qs主要是用于处理formData的数据

//创建 axios实例




const service = axios.create({
  // baseURL:process.env.NODE_ENV ==='production'?process.env.VUE_APP_BASE_API:'/',//api的base_url
  baseURL: 'https://app.nsfw.ifasun.com/',
  // baseURL: 'http://192.168.31.160:8081/',

  // 192.168.10.29
  timeout: Config.timeout, //请求时间

});

let loadingInstance;
//request拦截器
service.interceptors.request.use(
  config => {
    let headers = sessionStorage.getItem('tenantId');
    if(headers) config.headers['tenantId'] = headers;
    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);
//response拦截器
service.interceptors.response.use(
  response => {
    const code = response.status;
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.message,
      });
      if (loadingInstance) {
        loadingInstance.close();
      }
      return Promise.reject('error');
    } else {
      if (loadingInstance) {
        loadingInstance.close();
      }
      return response.data;
    }
  },
  error => {
    let code = 0;
    try {
      code = error.response.data.status;
    } catch (e) {
      if (error.toString().indexOf('Error:timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 5000,
        });
        return Promise.reject(error);
      }
    }
    if (code) {
      if (code == 401) {
        MessageBox.confirm(
          '登录状态已过期，您可以继续留在该页面，或者重新登录',
          '系统提示',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).then(() => {
          store.dispatch('Login').then(() => {
            location.reload(); //为了重新实例化vue-router对象
          });
        });
      } else if (code == 405) {
        router.push({ path: '/401' });
      } else {
        const errorMsg = error.response.data.message;
        if (errorMsg !== undefined) {
          Notification.error({
            title: errorMsg,
            duration: 5000,
          });
        }
      }
    } else {
      Notification.error({
        title: '接口请求失败',
        duration: 5000,
      });
    }
    return Promise.reject(error);
  }
);
export default service;
