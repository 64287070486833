import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import Echarts from '@com/echarts/index.vue';
import dataV from '@jiaminghi/data-view';

require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
import hls from 'videojs-contrib-hls';
import VideoPlayer from 'vue-video-player';
Vue.use(hls);
Vue.use(VideoPlayer);

Vue.use(dataV);
import scroll from 'vue-seamless-scroll';
Vue.use(scroll);
import { myTypeof, myConnect } from '@uti/instrument.js';
import 'element-ui/lib/theme-chalk/index.css';
import 'highlight.js/styles/github.css';
import '@/styles/reset.scss';
import '@/styles/index.scss';
import '@/styles/borderBox.scss';
Vue.use(ElementUI);
Vue.component('Echarts', Echarts);
// 全剧函数
Vue.prototype.$myTypeof = myTypeof;
Vue.prototype.$myConnect = myConnect; // 替代扩展运算符(...) 做数据/对象 合并时用
Vue.config.productionTip = false;
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import 'echarts-liquidfill';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
